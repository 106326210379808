import React from 'react'
import {Form, Select} from 'antd'

interface EnumSelectorInputProps {
    onChange: (e: any) => void,
    disabled: boolean,
    label: string,
    value: string,
    placeholder?: string,
    enumValues: {"name": string}[]
}

export default ({value, label, placeholder, enumValues, disabled, onChange}: EnumSelectorInputProps) =>
  <Form.Item label={label}>
    <Select
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
    >
      {enumValues.map(type =>
        <Select.Option value={type.name} key={type.name}>{type.name}</Select.Option>
      )}
    </Select>
  </Form.Item>
