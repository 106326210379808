import React, { useState } from 'react'
import { useMutation } from '../../mutation'
import { useQuery } from '../../query'
import { useHistory } from 'react-router-dom'
import { Button, Card, Alert, Spin } from 'antd'
import equal from 'fast-deep-equal'

import { CreateAirlineM } from './mutations'
import { Translations, Website } from '../../types'
import { CountriesQ, InputsCarrierTypeQ, InputsCarrierStatusQ } from './queries'
import * as yup from 'yup'

import TranslationsInput from '../shared/translations'
import CountryInput from '../shared/inputs/country'
import SlugsInput from '../shared/inputs/slugs'
import TextInput from '../shared/inputs/text'
import BoolInput from '../shared/inputs/bool'
import StringsInput from '../shared/inputs/strings'
import EnumSelectorInput from '../shared/inputs/enum_selector'

import '../shared/form.css'
import { preprocessJSONInputsGently, processErrors } from '../../utils'

interface CreateAirlineInput {
  address?: string,
  brandColor?: string,
  countryId: number,
  globalId: string,
  iata: string,
  icao?: string,
  internalCode?: string,
  isFlightable: boolean,
  isLowcost: boolean,
  phones?: string[],
  seoTranslations?: Translations,
  slugRu: string,
  status: string,
  translations: Translations,
  type: string,
  website: Website
}

export const validationSchema = yup.object().shape({
  address: yup.string().nullable(),
  brandColor: yup.string().nullable().test('len', 'Brand color must be exactly 6 symbols long',
    val => !val || val.length === 6).matches(/^[A-F0-9]{6}$/, 'Brand color must contain only A-F letters and numbers'),
  countryId: yup.number().required().integer().positive('Country must be selected'),
  globalId: yup.string().required().test('len', 'Global Id must be between 2 and 6 symbols',
    val => val.length >= 2 && val.length < 6).matches(/^[A-Z0-9]{2,5}$|^[А-Я0-9]{2,5}$/,
      'Global Id must contain either A-Z letters and numbers or А-Я letters and numbers'),
  iata: yup.string().required().length(2, 'IATA code must be exactly 2 symbols long').matches(/^[A-Z0-9]{2}$|^[А-Я0-9]{2}$/,
    'IATA must contain either A-Z letters and numbers or А-Я letters and numbers'),
  icao: yup.string().nullable().test('len', 'ICAO code must be exactly 3 letters long',
    val => !val || val.length === 3).matches(/^[A-Z]{3}$/,
      'only the following symbols are allowed in ICAO: A-Z'),
  internalCode: yup.string().nullable(),
  isFlightable: yup.boolean().required(),
  isLowcost: yup.boolean().required(),
  phones: yup.array().of(yup.string()).nullable(),
  seoTranslations: yup.object().shape({}).nullable(),
  slugRu: yup.string().required().matches(/^[a-z0-9-]+$/,
    'only the following symbols are allowed in Slug Ru: a-z, 0-9, -'),
  status: yup.string().required(),
  translations: yup.object().shape({
    en: yup.object().shape({ su: yup.string().required('English translation is required') }).required()
  }).required(),
  type: yup.string().required(),
  website: yup.object().required().shape({
    en: yup
    .string()
    .matches(/https?:\/\//, 'Website should start with https:// or http://')
    .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, 'Please enter a valid website URL')
  })
})

const defaultInput = {
  countryId: -1,
  globalId: '',
  iata: '',
  internalCode: '',
  isFlightable: true,
  isLowcost: false,
  seoTranslations: {},
  slugRu: '',
  status: 'ACTIVE',
  translations: {},
  type: 'AIRLINE',
  website: {}
}

export default () => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('main')
  const [errors, setErrors] = useState<Array<string>>([])
  const [input, setInput] = useState<CreateAirlineInput>(defaultInput)

  const [createAirline, { data: mutationData }] = useMutation(CreateAirlineM, setErrors)

  const performMutation = () =>
    validationSchema.validate(input)
      .then(_ => createAirline({ variables: { input: preprocessJSONInputsGently(input) } }),
        e => processErrors(e, setErrors))

  if (mutationData) history.push({
    pathname: `/airlines`,
    state: { createdId: mutationData.createAirline.id }
  })

  return (
    <Card
      tabList={[
        { key: 'main', tab: 'Main' },
        { key: 'translations', tab: 'Translations' },
        { key: 'seoTranslations', tab: 'SEO Translations' }
      ]}
      onTabChange={key => setActiveTab(key)}
      className='form'
    >
      {errors.map(e => <Alert type='error' message={e} key={e} />)}

      {
        {
          main: <MainForm
            setInput={setInput}
            input={input}
          />,
          translations: <TranslationsInput
            translations={input.translations}
            setTranslations={translations => setInput({ ...input, translations })}
          />,
          seoTranslations: <TranslationsInput
            translations={input.seoTranslations}
            setTranslations={seoTranslations => setInput({ ...input, seoTranslations })}
          />
        }[activeTab]
      }

      <Button
        style={{ float: 'left' }}
        disabled={equal(input, defaultInput)}
        onClick={() => setInput(defaultInput)}
        children='Reset Changes'
      />

      <Button
        onClick={performMutation}
        style={{ float: 'right' }}
        type='primary'
        children='Create airline'
      />
    </Card>
  )
}
const MainForm = ({ input, setInput }) => {
  const { loading, error: qError, data: inputsData } = useQuery(CountriesQ)
  const { loading: carrierTypeLoading, error: carrierTypeError, data: carrierTypeData } = useQuery(InputsCarrierTypeQ)
  const { loading: carrierStatusLoading, error: carrierStatusError, data: carrierStatusData } = useQuery(InputsCarrierStatusQ)

  if (loading) return (<Spin />)
  if (qError) return (<Alert message='Countries fetching error' />)
  if (carrierTypeLoading) return (<Spin />)
  if (carrierTypeError) return (<Alert message='Carrier types fetching error' />)
  if (carrierStatusLoading) return (<Spin />)
  if (carrierStatusError) return (<Alert message='Carrier types fetching error' />)

  const HEX_COLOR_REG_EXP = /[^0-9a-f]/gi

  return (
    <>
      <BoolInput
        label='is lowcost'
        checked={input.isLowcost}
        onChange={val => setInput({ ...input, isLowcost: val })}
      />

      <BoolInput
        label='is flightable'
        checked={!!input.isFlightable}
        onChange={val => setInput({ ...input, isFlightable: val })}
      />

      <EnumSelectorInput
        value={input.status}
        disabled={carrierStatusLoading}
        placeholder='airline status'
        label='Airline availability status'
        onChange={status => setInput({ ...input, status })}
        enumValues={carrierStatusData.__type.enumValues}
      />

      <EnumSelectorInput
        value={input.type}
        disabled={carrierTypeLoading}
        placeholder='airline type'
        label='Airline(carrier) type'
        onChange={type => setInput({ ...input, type })}
        enumValues={carrierTypeData.__type.enumValues}
      />

      <TextInput
        label='IATA'
        placeholder='IATA code, example: AB'
        value={input.iata}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-ZА-Я0-9]/i, '').toUpperCase()
          if (validatedValue.length <= 2) return setInput({ ...input, iata: validatedValue })
        }}
      />

      <TextInput
        label='ICAO'
        placeholder='ICAO code, example: ABC'
        value={input.icao}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-Z]/i, '').toUpperCase()
          if (validatedValue.length <= 3) return setInput({ ...input, icao: validatedValue })
        }}
      />

      <TextInput
        label='Internal code'
        placeholder='Internal code'
        value={input.internalCode}
        onChange={e => setInput({ ...input, internalCode: e.target.value })}
      />

      <TextInput
        label='Global Id'
        placeholder='Global Id, example: AB'
        value={input.globalId}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(/[^A-ZА-Я0-9]/i, '').toUpperCase()
          if (validatedValue.length <= 5) return setInput({ ...input, globalId: validatedValue })
        }}
      />

      <TextInput
        label='address'
        value={input.address}
        onChange={({ target: { value } }) => setInput({ ...input, address: value })}
      />

      <StringsInput
        label='phones'
        values={input.phones}
        disabled={loading}
        onChange={phones => setInput({ ...input, phones })}
      />

      <CountryInput
        countries={inputsData.countries}
        value={input.countryId}
        disabled={loading}
        onChange={countryId => setInput({ ...input, countryId })}
      />

      <SlugsInput
        onSlugRuChange={({ target: { value } }) => setInput({ ...input, slugRu: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
        onSlugChange={({ target: { value } }) => setInput({ ...input, slug: value.replace(/[^A-Z0-9-]/i, '').toLowerCase() })}
        slugRuVal={input.slugRu}
        slugVal={input.slug}
        disableSlugEn={true}
      />

      <TextInput
        label='brand color'
        placeholder='6-symbol HEX color code, example: FFFFFF'
        value={input.brandColor}
        onChange={({ target: { value } }) => {
          let validatedValue = value.replace(HEX_COLOR_REG_EXP, '').toUpperCase()
          if (validatedValue.length <= 6) return setInput({ ...input, brandColor: validatedValue })
        }}
      />

      <TextInput
        label='website'
        placeholder="Airline's website, example: https://example.com"
        value={input.website.en}
        onChange={({ target: { value } }) => {
          if (value.length === 0 ) {
            return setInput({ ...input, website: {} })
          } else {
            return setInput({ ...input, website: {"en": value} })
          }
        }}
      />

    </>
  )
}
